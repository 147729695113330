<template>
    <div>
        
        <div class="content content_top_margin" style="min-height: 175px;">
            <div class="content_inner  ">
                <div class="full_width ">
                    <div class="full_width_inner">

                        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
                            style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <h1>Hybrid working<br>
                                                                                Stretches</h1>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p>
                                                                                <router-link :to="{ name: 'hybrid-working-a45' }">
                                                                                <em>Up
                                                                                        Next: </em> Hybrid Working <img
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
                                                                                        alt="" width="64"
                                                                                        height="37">
                                                                                </router-link>
                                                                                        </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 52px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>


                                                <div
                                                    class="wpb_text_column wpb_content_element  vc_custom_1637285740177">
                                                    <div class="wpb_wrapper">
                                                        <h2>Be kind to yourself</h2>
                                                        <p>Even if your work environment changes day to day, the one
                                                            thing that remains constant is you! So take care of your
                                                            body wherever you find yourself working. You can do these
                                                            stretches before work, after work and in between work
                                                            projects.</p>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div
                                                            class="center wpb_column vc_column_container vc_col-sm-2 vc_hidden-sm vc_hidden-xs">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                        <div class="center wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p><a class="image-popup headerVideoLink popup-modal"
                                                                                    href="#" data-bs-toggle="modal"
																						data-bs-target="#exampleModal1"><br>
                                                                                    <img src="../../assets/images/airbnb/Airbnb-Hybrid-stress-buster-.jpg"
                                                                                        alt="Stress Relief"><br>
                                                                                    <span class="button"><img src="../../assets/images/airbnb/playarrow.png" width="15" height="15">
                                                                                        Stress Relief</span><br>
                                                                                </a></p>

                                                                        </div>
                                                                    </div>
                                                                    <div class="vc_empty_space" style="height: 32px">
                                                                        <span class="vc_empty_space_inner">
                                                                            <span class="empty_space_image"></span>
                                                                        </span></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="center wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p><a class="image-popup headerVideoLink popup-modal"
                                                                                    href="#" data-bs-toggle="modal"
																						data-bs-target="#exampleModal2"><br>
                                                                                    <img src="../../assets/images/airbnb/Airbnb-Hybrid-Stretches-Revitalize.jpg"
                                                                                        alt="Revitalize"><br>
                                                                                    <span class="button"><img src="../../assets/images/airbnb/playarrow.png" width="15" height="15">
                                                                                        Revitalize</span><br>
                                                                                </a></p>

                                                                        </div>
                                                                    </div>
                                                                    <div class="vc_empty_space" style="height: 32px">
                                                                        <span class="vc_empty_space_inner">
                                                                            <span class="empty_space_image"></span>
                                                                        </span></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="wpb_column vc_column_container vc_col-sm-2 vc_hidden-sm vc_hidden-xs">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div
                                                            class="wpb_column vc_column_container vc_col-sm-2 vc_hidden-sm vc_hidden-xs">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                        <div class="center wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p><a class="image-popup headerVideoLink popup-modal"
                                                                                     href="#" data-bs-toggle="modal"
																						data-bs-target="#exampleModal3"><br>
                                                                                    <img src="../../assets/images/airbnb/Airbnb-Hybrid-Stretch-Relax.jpg"
                                                                                        alt="Relaxation"><br>
                                                                                    <span class="button"><img src="../../assets/images/airbnb/playarrow.png" width="15" height="15">
                                                                                        Relaxation</span><br>
                                                                                </a></p>

                                                                        </div>
                                                                    </div>
                                                                    <div class="vc_empty_space" style="height: 32px">
                                                                        <span class="vc_empty_space_inner">
                                                                            <span class="empty_space_image"></span>
                                                                        </span></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="center wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p><a class="image-popup headerVideoLink popup-modal"
                                                                                    href="#" data-bs-toggle="modal"
																						data-bs-target="#exampleModal4"><br>
                                                                                    <img src="../../assets/images/airbnb/Airbnb-Hybrid-Stretches-Recharge-.jpg"
                                                                                        alt="Recharge"><br>
                                                                                    <span class="button"><img src="../../assets/images/airbnb/playarrow.png" width="15" height="15">
                                                                                        Recharge</span><br>
                                                                                </a></p>

                                                                        </div>
                                                                    </div>
                                                                    <div class="vc_empty_space" style="height: 32px">
                                                                        <span class="vc_empty_space_inner">
                                                                            <span class="empty_space_image"></span>
                                                                        </span></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="wpb_column vc_column_container vc_col-sm-2 vc_hidden-sm vc_hidden-xs">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-1/5">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_single_image wpb_content_element vc_align_right">
                                                    <div class="wpb_wrapper">

                                                        <a href="https://www.brightday.com/download-trial"
                                                            target="_blank">
                                                            <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                                <img width="150" height="150"
                                                                    src="../../assets/images/airbnb/ZG0RmEdo-150x150.jpg"
                                                                    class="vc_single_image-img attachment-thumbnail"
                                                                    alt="" loading="lazy" title="ZG0RmEdo"
                                                                    ></div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="wpb_column vc_column_container vc_col-sm-3/5">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h3 style="text-align: center;">Want to improve your posture?
                                                            We’ve got your back. Literally.</h3>
                                                        <p style="text-align: center;">Check out Airbnb’s newest
                                                            resource to help you track and improve your posture
                                                            throughout your work day.</p>
                                                        <p style="text-align: center;"><em><b>Activation Code:
                                                                    airbnbairbnb</b></em></p>
                                                        <p style="text-align: center;"><a class="button orange"
                                                                href="https://www.brightday.com/download-trial"
                                                                target="_blank" rel="noopener">Download Now</a></p>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="wpb_column vc_column_container vc_col-sm-1/5">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>Continue your work from anywhere journey</h2>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'hybrid-working-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Hybrid.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb---Hybrid"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'hybrid-working-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Hybrid Working</h4>
                                                                            <p>Shuttling between the office and home?
                                                                                We’ve got you covered.</p>
                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'staying-focused-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Hybrid-Staying-Focused.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Hybrid-Staying-Focused"
                                                                                        >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'staying-focused-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Staying Focused</h4>
                                                                            <p>Staying focused requires skill and
                                                                                practice. Learn how to stay in the zone.
                                                                            </p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'mobility-kits-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="500"
                                                                                        src="../../assets/images/airbnb/Airbnb-Hybrid-Mobility-Kits.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb---Hybrid---Mobility-Kits"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'mobility-kits-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Mobility kits</h4>
                                                                                <p>With the right tools, it’s easy to stay
                                                                                    comfortable and productive wherever you
                                                                                    work.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'working-from-the-cafe-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="2560" height="1002"
                                                                                        src="../../assets/images/airbnb/REPLACEMENT-WORK-IN-CAFE-PARK-ETC2-scaled.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="REPLACEMENT - WORK IN CAFE, PARK ETC2"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'working-from-the-cafe-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Working From the Cafe</h4>
                                                                                <p>Working from the cafe? Ergo has your back
                                                                                    while you get caffeinated.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                            <router-link :to="{ name: 'managing-your-schedule-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="502"
                                                                                        src="../../assets/images/airbnb/Airbnb-I-am-Hybrid-managing-schedule.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb---I-am-Hybrid---managing-schedule"
                                                                                        >
                                                                                </div>
                                                                            </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'managing-your-schedule-a45' }">
                                                                            <div class="wpb_wrapper">
                                                                                <h4>Manage your schedule</h4>
                                                                                <p>Discover how to get your schedule under
                                                                                    control in three easy steps.</p>
                                                                            </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'work-anywhere-stretches-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="750" height="499"
                                                                                        src="../../assets/images/airbnb/Airbnb-Hybrid-Footer-Work-Anywhere-Stretches.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-Hybrid-Footer---Work-Anywhere-Stretches"
                                                                                        >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'work-anywhere-stretches-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Work Anywhere Stretches</h4>
                                                                            <p>Where you go, you take yourself with you.
                                                                                Be kind to your body.</p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">
                            <div class=" full_section_inner clearfix">
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_raw_code wpb_content_element wpb_raw_html">
                                                <div class="wpb_wrapper">
                                                    <div id="headerPopup" class="mfp-hide"
                                                        style="padding:56.25% 0 0 0;position:relative;"><iframe
                                                            src="https://player.vimeo.com/video/580346529?h=3561a2038c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen=""
                                                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                                            title="ERGO_in 30 seconds"></iframe></div>

                                                    <div id="popupVideo1" class="mfp-hide"
                                                        style="padding:56.25% 0 0 0;position:relative;"><iframe
                                                            src="https://player.vimeo.com/video/320059710?h=a3ee06102d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen=""
                                                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                                            title="Neck Stretch"></iframe></div>

                                                    <div id="popupVideo2" class="mfp-hide"
                                                        style="padding:56.25% 0 0 0;position:relative;"><iframe
                                                            src="https://player.vimeo.com/video/320351986?h=055c617379&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen=""
                                                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                                            title="Shoulder Opener"></iframe></div>

                                                    <div id="popupVideo3" class="mfp-hide"
                                                        style="padding:56.25% 0 0 0;position:relative;"><iframe
                                                            src="https://player.vimeo.com/video/320352751?h=936e7f1340&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen=""
                                                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                                            title="Palm, Forearm, Finger Stretch"></iframe></div>

                                                    <div id="popupVideo4" class="mfp-hide"
                                                        style="padding:56.25% 0 0 0;position:relative;"><iframe
                                                            src="https://player.vimeo.com/video/320352657?h=424e9f51b8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen=""
                                                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                                            title="Gentle Back Twist"></iframe></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                         <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed1"
												src="https://player.vimeo.com/video/320059710?h=a3ee06102d&badge=0&autopause=0&player_id=0&app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>
                          <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal2">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed2"
												src="https://player.vimeo.com/video/320351986?h=055c617379&badge=0&autopause=0&player_id=0&app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>
                          <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal3">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed3"
												src="https://player.vimeo.com/video/320352751?h=936e7f1340&badge=0&autopause=0&player_id=0&app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>
                          <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal4">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed4"
												src="https://player.vimeo.com/video/320352657?h=424e9f51b8&badge=0&autopause=0&player_id=0&app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>


                    </div>
                </div>
               

            </div>
        </div>

    </div>
</template>
<script>
    //import carousel from "vue-owl-carousel"
    export default {
        name: 'work-anywhere-stretches-a45',
         methods: {
			hideModal() {
			document.getElementById("closed1").src += "";
			},
            hideModal2() {
			document.getElementById("closed2").src += "";
			},
            hideModal3() {
			document.getElementById("closed3").src += "";
			},
            hideModal4() {
			document.getElementById("closed4").src += "";
			},
		}
    }
</script>